var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('Spinners',{staticClass:"py-20"}):_c('div',{staticClass:"hidden lg:flex justify-center bg-center py-12 w-full ",class:{
            'bg-repeat': _vm.pageOptions['bgRepeat'],
            'bg-cover': !_vm.pageOptions['bgRepeat']
        },style:('background-image:url(' + _vm.homeBG + ')')},[_c('div',{staticClass:"container flex justify-center"},[_c('div',{staticClass:" w-4/12 flex-row "},[_c('div',{staticClass:"rounded-xl  featured-content overflow-hidden p-6 z-10",attrs:{"id":"verticalCarouselSwipperContainer"}},[_c('section',{staticClass:"rounded-xl z-20"},[_c('h3',{staticClass:"text-xl home-prevsession-text-color mb-2 text-center"},[_vm._v(" "+_vm._s(_vm.homeLabel.previousSessionHeader)+" ")]),(_vm.isFeaturedDataLoading)?_c('Spinners',{staticClass:"py-8"}):_c('VerticalCarousel',{staticClass:"swiper-container--custom mt-16 2xl:mt-8 pb-2",attrs:{"featuredData":_vm.featuredSession,"textHeader":_vm.homeLabel.previousSessionHeader}})],1)]),(_vm.useStaticSwimlane)?_c('div',{staticClass:"w-10/12 py-8 hidden md:flex flex-col"},[_c('h4',{staticClass:"text-2xl  text-white text-center"},[_vm._v(" Thank you to our sponsor ")]),_vm._l((_vm.useStaticSwimlane),function(sponsor){return _c('router-link',{key:sponsor.key,staticClass:"pr-2 inline-flex top-0",attrs:{"to":sponsor.link}},[_c('img',{staticClass:"block object-contain flex-none w-full rounded-xl",attrs:{"src":_vm.logoImageSrc(sponsor.logo),"alt":sponsor.logo}})])})],2):_vm._e()]),_c('div',{staticClass:"w-4/6 pl-10 xl:pl-0",class:[("order-" + (1))]},[[(_vm.isLoadingVideo)?_c('Spinners',{staticClass:"my-16"}):(_vm.featuredVideo && _vm.featuredVideo.videoUrl)?_c('div',{key:"db-video",staticClass:"container"},[_c('video-component',{attrs:{"sessionData":{
                                sessionId: null,
                                title: _vm.featuredVideo.title
                            },"options":{
                                autoplay: false,
                                controls: true,
                                sources: [
                                    {
                                        src: _vm.featuredVideo.videoUrl,
                                        type: 'application/x-mpegURL'
                                    }
                                ],
                                poster: _vm.featuredVideo.thumbnail
                            }}})],1):(_vm.featuredItem && !_vm.featuredVideo.videoUrl)?_c('div',{staticClass:"m-auto w-full"},[_c('img',{staticClass:"object-contain w-full h-full",attrs:{"src":_vm.featuredItem,"alt":"Featured Item"}})]):(_vm.notProduction && !_vm.featuredItem)?_c('div',{key:"default-video",staticClass:"container block m-auto"},[_c('div',{staticClass:"videoWrapper"},[_c('iframe',{staticClass:"video",attrs:{"src":"https://player.vimeo.com/video/403106834","width":"640","height":"564","title":"Introduction to Virtual Meeting Platform","frameborder":"0","allow":"autoplay; fullscreen","allowfullscreen":"","muted":""}})])]):_vm._e()],_c('ActionIcon',{attrs:{"innerClasses":"rounded-xl bg-white py-12"}}),(_vm.useDefaultAttendeeCard)?_c('div',[_c('h4',{staticClass:"text-2xl text-center pb-8 m-auto",style:("color: var(--home-attendee-header)")},[_vm._v(" "+_vm._s(_vm.attendeeSwimlanes[0].headerLabel)+" ")]),(_vm.useDefaultAttendeeCard)?_c('div',{staticClass:"grid grid-cols-4 gap-4"},_vm._l((_vm.attendeeSwimlane),function(attendee){return _c('AttendeeCard',{key:attendee.attendeeId,attrs:{"data":attendee}})}),1):_vm._e()]):_c('div',{staticClass:"mt-4 grid gap-2 grid-cols-4 ",class:[
                        _vm.attendeeSwimlane.length === 5
                            ? '2xl:grid-cols-5'
                            : ''
                    ]},[(!_vm.attendeeSwimlane.length)?_c('div',{staticClass:"py-8 w-full bg-white opacity-75 col-span-4 rounded-xl relative"},[_c('Spinners')],1):_vm._l((_vm.attendeeSwimlane),function(attendee){return _c('figure',{key:attendee.attendeeId,staticClass:"hidden lg:flex rounded-xl attendee-container  flex-col justify-start items-center pb-2"},[_c('div',{staticClass:"text-center py-1 text-white font-semibold text-md rounded-t-xl bg-card--header  w-full"},[_vm._v(" Who’s Here? ")]),(attendee.imgPath)?_c('img',{staticClass:"rounded-full mt-2 object-cover  h-16 w-16",attrs:{"src":_vm.myImage(attendee.imgPath),"alt":("Photo of " + (attendee.firstName) + " " + (attendee.lastName))}}):_c('span',{staticClass:"grid rounded-full mt-2   bg-dividers h-16 w-16 p-2"},[_c('span',{staticClass:"text-dark m-auto text-xl"},[_vm._v(_vm._s(attendee.firstName[0])+_vm._s(attendee.lastName[0])+" ")])]),_c('router-link',{staticClass:"text-center font-bold  px-2 card-title",attrs:{"to":("/attendees/attendee-profile/" + (attendee.attendeeId))}},[_vm._v(" "+_vm._s(attendee.firstName)+" "+_vm._s(attendee.lastName)+" ")]),_c('span',{staticClass:"text-center text-sm px-2"},[_vm._v(" "+_vm._s(attendee.companyName)+" ")])],1)})],2),_c('b-button',{staticClass:"block lg:hidden xl:block px-6  capitalize my-8 font-semibold m-auto",attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.routeTo('Attendees')}}},[_vm._v(" Search Attendees ")]),(
                        _vm.pageOptions.section.featuredSolutionProvider
                            .useSection
                    )?_c('section',{staticClass:"rounded-xl px-8 py-4 mt-4  home-sponsor--container"},[_c('section',{staticClass:"flex justify-between items-start"},[_c('h3',{staticClass:"block  text-primary text-lg font-semibold mb-0"},[_vm._v(" "+_vm._s(_vm.homeLabel.featuredSolutionHeader)+" ")]),_c('router-link',{staticClass:"text-lg no-underline text-primary hover:text-accent",attrs:{"to":"/tradeshow"}},[_vm._v(" View All")])],1),(_vm.isSponsorSwimlane)?_c('Spinners',{staticClass:"py-8"}):_c('ExhibitorSwimlane',{staticClass:"relative",attrs:{"hideCompanyName":_vm.pageOptions.section.featuredSolutionProvider
                                .hideCompanyName,"numberOfslidesPerGroup":_vm.pageOptions.section.featuredSolutionProvider
                                .numberOfslidesPerGroup}})],1):_vm._e(),(_vm.useTwitterWidget)?_c('TwitterWidget',{staticClass:"mt-4"}):_vm._e()],2)])]),_c('Home',{staticClass:"lg:hidden"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }