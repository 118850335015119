





















































































































































































































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import Spinners from "@/components/utilities/Spinners.vue";
import eventHub from "@/event-hub";

import VerticalCarousel from "@/components/shared/VerticalCarousel.vue";
import VideoComponent from "@/components/shared/VideoComponent.vue";
import Home from "@/components/home/DefaultHomePage.vue";
import ExhibitorSwimlane from "@/components/home/section/ExhibitorSwimlane.vue";
import TwitterWidget from "@/components/shared/TwitterWidget.vue";
import AttendeeCard from "@/components/shared/AttendeeCard.vue";

import ActionIcon from "@/components/home/section/ActionIcon.vue";

import featuredContentVuexModule from "@/store/vuex-modules/featuredContent";
import featuredVideoVuexModule from "@/store/vuex-modules/getFeaturedVideo";
import featuredAttendeeVuexModule from "@/store/vuex-modules/getFeaturedAttendees";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";

import {
    SessionDataArray,
    FeaturedContentObject,
    FeaturedVideoObject,
    AttendeeSwimlaneObject,
    AttendeeDataObject
} from "@/types/interfaces";

const featuredContentStore = getModule(featuredContentVuexModule);
const featuredVideoStore = getModule(featuredVideoVuexModule);
const featuredAttendeeStore = getModule(featuredAttendeeVuexModule);

const sessionStore = getModule(sessionVuexModule);
const tradeshowStore = getModule(tradeshowVuexModule);

export default Vue.extend({
    data() {
        return {
            publicPath: process.env.BASE_URL,
            isLoading: true,
            isLoadingVideo: false,
            hasLobbyData: false,
            isAttendeeLoading: true,
            loadFetauredContent: false,
            featuredSession: [] as any,
            isFeaturedDataLoading: true,
            isSponsorSwimlane: true,
            containerHeight: 0
        };
    },

    components: {
        Home,
        Spinners,
        VideoComponent,
        VerticalCarousel,
        ExhibitorSwimlane,
        TwitterWidget,
        ActionIcon,
        AttendeeCard
        // AttendeeSwimlane: () => {
        //     const returnResult = store.getters.getPageOptions("home").section
        //         ?.attendeeSwimlane?.componentToLoad;
        //     return import(`@/components/attendees/swimlanes/${returnResult}`);
        // }
    },
    directives: {
        addSvg: {
            inserted() {
                eventHub.$emit("HomeLobbySvgInserted");
            }
        }
    },
    computed: {
        useDefaultAttendeeCard(): boolean {
            return this.pageOptions.componentVariance
                .filter((item: Record<string, string | boolean>) => {
                    if (item.isActive && item.useDefaultAttendeeCard) {
                        return item;
                    }
                })
                .find((item: Record<string, string | boolean>) => {
                    if (item.useDefaultAttendeeCard) {
                        return item.useDefaultAttendeeCard;
                    }
                });
        },

        notProduction(): boolean {
            return Boolean("production" !== process.env.NODE_ENV);
        },

        pageOptions(): Record<string, any> {
            return this.$store.getters.getPageOptions("home") || {};
        },

        featuredContent(): Array<FeaturedContentObject> {
            return featuredContentStore.featuredContent;
        },

        sessionData(): SessionDataArray {
            return sessionStore.sessionPromos ? sessionStore.sessionPromos : [];
        },
        featuredItem(): string {
            return this.$store.getters.getPageOptions("home").section
                .featuredItem;
        },

        featuredVideo(): FeaturedVideoObject {
            return featuredVideoStore.featuredVideo;
        },

        attendeeSwimlane(): Array<AttendeeDataObject> | undefined {
            return this.RandomAttendee();
        },

        attendeeSwimlanes(): Array<AttendeeSwimlaneObject> {
            return this.attendeePageOptions.swimLanes
                ? this.attendeePageOptions.swimLanes
                : [];
        },

        attendeePageOptions(): Record<string, any> {
            return this.$store.getters.getPageOptions("attendees");
        },

        homeLabel(): Record<string, string> {
            return this.$store.getters.homeLabel;
        },

        logoImage(): string {
            return "/logos/partnership.svg";
        },
        homeBG(): string {
            return "/home-bg/" + this.pageOptions.imageBackground;
        },
        userInfo(): Record<string, string> {
            return this.$store.getters.userInfo;
        },

        myOwnProfileId(): string {
            return this.userInfo.id;
        },

        sponsorSwimlane() {
            const partner = tradeshowStore.tradeshowCompanies.partner;
            const premier = tradeshowStore.tradeshowCompanies.premier;
            const title = tradeshowStore.tradeshowCompanies.title;
            // try {
            //     return [...partner, ...premier, ...title];
            // } catch (err) {
            //     console.error(err);
            //     return [];
            // }
            return partner;
        },

        useStaticSwimlane(): Record<string, any> {
            const options = this.$store.getters.getPageOptions("home") || {};

            return options.staticSponsorSwimlane;
        },

        useTwitterWidget(): boolean {
            return Boolean(this.pageOptions.section?.twitterWidget?.active);
        }
    },
    /**
     * Lifecycle
     */
    created() {
        this.isLoading = false;
        const newPromises = [
            featuredContentStore.getFeaturedContent(),
            featuredVideoStore.getFeaturedVideo(),
            sessionStore.getSessionData({
                isDemoData: false
            }),
            tradeshowStore.getTradeshowCompanies()
        ];

        Promise.allSettled(newPromises)
            .then(() => {
                this.featuredSession = [...this.featuredContent];
                this.isLoadingVideo = false;
            })
            .finally(() => {
                this.loadFetauredContent = true;
                this.isFeaturedDataLoading = false;
                this.isSponsorSwimlane = false;

                const elmt = document.getElementById(
                    "verticalCarouselSwipperContainer"
                ) as HTMLElement;

                this.containerHeight = elmt.offsetHeight;

                // alert(containerHeight.offsetHeight);
            });

        featuredAttendeeStore
            .getFeaturedAttendees(this.attendeeSwimlanes)
            .finally(() => (this.isAttendeeLoading = false));
    },
    methods: {
        RandomAttendee() {
            let attendeeCounter;

            if (screen.width < 1400 || this.useDefaultAttendeeCard) {
                attendeeCounter = 4;
            } else {
                attendeeCounter = 5;
            }
            try {
                return featuredAttendeeStore.featuredAttendeeSwimlanes
                    .flatMap((item: any) => {
                        return item.data;
                    })
                    .slice(0, attendeeCounter);
            } catch (error) {
                console.error(error);
            }
        },

        myImage(img: string) {
            return img ? img : "/fallback-images/avatar.png";
        },

        routeTo(routeString: string) {
            this.$router.push({
                name: routeString
            });
        },

        logoImageSrc(str: string) {
            return `${this.publicPath}staticSponsorLogo/${str}`;
        }
    }
});
